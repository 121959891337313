(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$('.list-duty li').mouseenter(function () {
  var $sub = $(this).find('.text');
  var autoHeight = $sub.get(0).scrollHeight;
  $sub.css({
    "height": autoHeight
  });
}).mouseleave(function () {
  var $sub = $(this).find('.text');
  $sub.css({
    "height": 0
  });
});
var swiperPublic = new Swiper('#swiper-public', {
  loop: true,
  speed: 500,
  pagination: {
    el: '.swiper-pagination-public',
    clickable: true
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd() {}
  },
  autoplay: true
});

},{}],2:[function(require,module,exports){
"use strict";

var swiperPro = new Swiper('#swiper-pro', {
  loop: true,
  // loopedSlides: 10,
  speed: 500,
  pagination: {
    el: '.swiper-pagination-pro',
    clickable: true
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd() {}
  },
  autoplay: true
});

function createCaseSwiper() {
  return new Swiper('#swiper-case', {
    loop: false,
    // loopedSlides: 10,
    spaceBetween: 16,
    slidesPerGroup: 3,
    slidesPerView: 3,
    speed: 500,
    pagination: {
      el: '.swiper-pagination-case',
      clickable: true
    },
    on: {
      init: function init() {},
      slideChangeTransitionStart: function slideChangeTransitionStart() {},
      transitionEnd: function transitionEnd() {}
    },
    autoplay: true
  });
}

var initCase = false;
var swiperCase;

function swiperCasePC() {
  if ($(window).width() >= 760) {
    if (!initCase) {
      initCase = true;
      swiperCase = createCaseSwiper();
    }
  } else if (initCase) {
    swiperCase.destroy();
    initCase = false;
  }
}

if ($('#swiper-case').length) {
  $(window).on('resize', function () {
    swiperCasePC();
  }).resize();
}

},{}],3:[function(require,module,exports){
"use strict";

if ($('.count-to').length) {
  $('.count-to').one('inview', function (event, isInView) {
    if (isInView) {
      $('.count-to').countTo();
    } else {}
  });
}

},{}],4:[function(require,module,exports){
"use strict";

$('.form-box input.required').on('input', function () {
  toggleLabel.call(this);
});
var toggleLabel = debounce(function () {
  var value = $(this).val();
  var name = $(this).attr('name');

  if ($.trim(value) == '') {
    $('label[for=' + name + ']').show();
  } else {
    $('label[for=' + name + ']').hide();
  }
}, 600, true);
var province = [{
  "name": "北京市",
  "id": "110000000000"
}, {
  "name": "天津市",
  "id": "120000000000"
}, {
  "name": "河北省",
  "id": "130000000000"
}, {
  "name": "山西省",
  "id": "140000000000"
}, {
  "name": "内蒙古自治区",
  "id": "150000000000"
}, {
  "name": "辽宁省",
  "id": "210000000000"
}, {
  "name": "吉林省",
  "id": "220000000000"
}, {
  "name": "黑龙江省",
  "id": "230000000000"
}, {
  "name": "上海市",
  "id": "310000000000"
}, {
  "name": "江苏省",
  "id": "320000000000"
}, {
  "name": "浙江省",
  "id": "330000000000"
}, {
  "name": "安徽省",
  "id": "340000000000"
}, {
  "name": "福建省",
  "id": "350000000000"
}, {
  "name": "江西省",
  "id": "360000000000"
}, {
  "name": "山东省",
  "id": "370000000000"
}, {
  "name": "河南省",
  "id": "410000000000"
}, {
  "name": "湖北省",
  "id": "420000000000"
}, {
  "name": "湖南省",
  "id": "430000000000"
}, {
  "name": "广东省",
  "id": "440000000000"
}, {
  "name": "广西壮族自治区",
  "id": "450000000000"
}, {
  "name": "海南省",
  "id": "460000000000"
}, {
  "name": "重庆市",
  "id": "500000000000"
}, {
  "name": "四川省",
  "id": "510000000000"
}, {
  "name": "贵州省",
  "id": "520000000000"
}, {
  "name": "云南省",
  "id": "530000000000"
}, {
  "name": "西藏自治区",
  "id": "540000000000"
}, {
  "name": "陕西省",
  "id": "610000000000"
}, {
  "name": "甘肃省",
  "id": "620000000000"
}, {
  "name": "青海省",
  "id": "630000000000"
}, {
  "name": "宁夏回族自治区",
  "id": "640000000000"
}, {
  "name": "新疆维吾尔自治区",
  "id": "650000000000"
}];

function createProvinceSelect() {
  var html = $('#select-province').html();

  for (var i = 0; i < province.length; i++) {
    html += '<option value="' + province[i].name + '">' + province[i].name + '</option>';
  }

  $('#select-province').html(html);
}

createProvinceSelect();

},{}],5:[function(require,module,exports){
"use strict";

var _util = require("./_util.js");

if ($('#banner').length) {
  // var videoIndex = $('.bg-video-cover').parents('.swiper-slide').index()
  var wxAutoPlay = function wxAutoPlay() {
    document.addEventListener('WeixinJSBridgeReady', function () {
      $('.video').each(function () {
        this.play();
      });
    }, false);
    /*		document.addEventListener('touchstart', function ()
    			{
    				$('.video').each(function(){
    					this.play();
    				})
    			},
    			false
    		);*/
  };

  var innerSwiper = function innerSwiper($this) {
    var $el = $this;
    var $pagination = $this.find('.swiper-pagination');
    new Swiper($el, {
      speed: 500,
      pagination: {
        el: $pagination,
        clickable: true
      },
      autoplay: true,
      loop: true,
      on: {
        beforeInit: function beforeInit() {},
        init: function init() {},
        transitionStart: function transitionStart() {}
      }
    });
  };

  var innerNews = function innerNews($this) {
    var $el = $this;
    var $pagination = $this.find('.swiper-pagination');
    new Swiper($el, {
      speed: 500,
      pagination: {
        el: $pagination,
        clickable: true
      },
      autoplay: false,
      slidesPerView: 3,
      breakpoints: {
        760: {
          slidesPerView: 1
        }
      },
      loop: false,
      on: {
        beforeInit: function beforeInit() {},
        init: function init() {},
        transitionStart: function transitionStart() {},
        resize: function resize() {// console.log('resize');

          /*console.log(this);
          this.params.width = $el.width();
          setTimeout(()=>{
              this.update();
          },0)*/
        }
      }
    });
  };

  var createAccordionSwiper = function createAccordionSwiper() {
    if ($('#swiper-accordion').length) return;
    var slide = '';
    $('.accordion li').each(function () {
      var html = $(this).html();
      slide += '<div class="swiper-slide">' + html + '</div>';
    });
    var swiper = '<div class="swiper-container accordion swiper-accordion" id="swiper-accordion">' + '<div class="swiper-wrapper">' + slide + '</div>' + '<div class="swiper-pagination-circle swiper-pagination-solid swiper-pagination swiper-pagination-accordion"></div>' + '</div>';
    $(swiper).insertAfter('.accordion');
  };

  var swiperCard = function swiperCard() {
    if ($(window).width() <= 760) {
      createAccordionSwiper();

      if (!initAccordion) {
        initAccordion = true;
        swiperAccordion = new Swiper("#swiper-accordion", {
          loop: true,
          pagination: {
            el: ".swiper-pagination-accordion",
            clickable: true
          }
        });
      }
    } else if (initAccordion) {
      // swiperAccordion.destroy();
      initAccordion = false;
      $('#swiper-accordion').remove();
    }
  };

  /*	$('.video-btn').click(function (e) {
  		var $video = $('.bg-video-cover');
  		if( $video.prop('muted')) {
  		    $video.prop('muted', false);
  		} else {
  			$video.prop('muted', true);
  		}
  		e.preventDefault();
  	});*/
  var videoIndex = $('.bg-video-cover').map(function () {
    return $(this).parents('.swiper-slide').index();
  }).get();
  wxAutoPlay();
  var swiperBanner = new Swiper('#banner', {
    loop: true,
    speed: 500,
    pagination: {
      el: '#banner-swiper-pagination',
      clickable: true,
      renderBullet: function renderBullet(index, className) {
        return '<span class="' + className + '">' + '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="-1 -1 34 34"><circle class="ring" cx="16" cy="16" r="16"></circle></svg>' + '</span>';
      }
    },
    on: {
      init: function init() {},
      slideChangeTransitionStart: function slideChangeTransitionStart() {},
      transitionEnd: function transitionEnd(i) {
        var video = $('.swiper-slide-active').find('.bg-video-cover')[0];

        if (video && videoIndex.includes(this.realIndex)) {
          video.play();
        } else {
          $('.bg-video-cover').each(function () {
            this.pause();
          });
        }

        $('#banner .swiper-slide-active').removeClass('move-out').addClass('move-in').siblings().removeClass('move-in');
      }
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.banner-next',
      prevEl: '.banner-prev'
    }
  });
  (0, _util.setWrap)('.i-row1 .wp-full');
  var swiperSolutionOuter = new Swiper('#swiper-solution-outer', {
    speed: 500,
    allowTouchMove: false,

    /*autoplay: {
       delay: 5000,
    },*/
    loop: true,
    breakpoints: {
      760: {
        allowTouchMove: true,
        autoHeight: true
      }
    },
    on: {
      beforeInit: function beforeInit() {},
      init: function init() {
        $('#tab-solution a:first').addClass('on');
      },
      transitionStart: function transitionStart() {
        $('#tab-solution a').eq(this.realIndex).addClass('on').siblings('a').removeClass('on');
      }
    }
  });
  $('#tab-solution a').click(function (e) {
    var i = $(this).index();
    swiperSolutionOuter.slideToLoop(i);
    return false;
  });
  $('.swiper-solution-inner').each(function () {
    innerSwiper($(this));
  });
  var swipetThumb = new Swiper('#swiper-product-thumb', {
    loop: true,
    speed: 500,
    allowTouchMove: false,
    initialSlide: 1,
    on: {
      init: function init() {},
      slideChangeTransitionStart: function slideChangeTransitionStart() {},
      transitionEnd: function transitionEnd() {}
    },
    autoplay: false
  });
  $('#next-solution').click(function () {
    // swipetThumb
    swipetThumb.slideNext();
    swiperSolutionOuter.slideNext();
    return false;
  });
  var swipeNews = new Swiper('#swiper-news', {
    loop: false,
    speed: 500,
    on: {
      init: function init() {
        $('#tab-news a:first').addClass('on');
      },
      slideChangeTransitionStart: function slideChangeTransitionStart() {
        $('#tab-news a').eq(this.realIndex).addClass('on').siblings('a').removeClass('on');
      },
      transitionEnd: function transitionEnd() {}
    },
    autoplay: false
  });
  $('.swiper-news-inner').each(function () {
    innerNews($(this));
  });
  $('#tab-news a').click(function (e) {
    var i = $(this).index();
    swipeNews.slideTo(i);
    return false;
  });
  $(window).on('resize', function () {
    (0, _util.setWrap)('.i-row1 .wp-full');
    (0, _util.setWrap)('.accordion li:first .after');
    swiperCard();
  });
  var initAccordion = false;
  var swiperAccordion;
  swiperCard();
}

function accordion() {
  var timer;
  $('.accordion li').mouseenter(function () {
    // clearTimeout(timer);
    var $this = $(this); // timer = setTimeout(function(){

    $this.addClass('show-after').siblings('li').removeClass('show-after'); // },500)
  });
  $('.accordion li:first').addClass('show-after');
}

(0, _util.setWrap)('.accordion li:first .after');
accordion();
$('.bg-video-cover').on('play', function () {
  swiperBanner.autoplay.stop();
});
$('.bg-video-cover').on('ended', function () {
  swiperBanner.slideNext(0);
  swiperBanner.autoplay.start();
});

},{"./_util.js":17}],6:[function(require,module,exports){
"use strict";

var swiperHistoryHd = new Swiper('#swiper-history-hd', {
  loop: false,
  // loopedSlides: 10,
  speed: 500,
  slidesPerView: 'auto',
  slideToClickedSlide: false,
  centeredSlides: false,
  breakpoints: {
    760: {
      centeredSlides: true,
      slidesPerView: 3
    }
  },

  /*navigation: {
  		nextEl: '.swiper-history-next',
  		prevEl: '.swiper-history-prev',
  	},*/
  // initialSlide: 3,
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {
      var index_currentSlide = this.realIndex;
      swiperHistoryBd.slideTo(index_currentSlide, 500, false);
      $('#swiper-history-hd').find('.swiper-slide').eq(index_currentSlide).addClass('on').siblings().removeClass('on');
    },
    transitionEnd: function transitionEnd() {}
  }
});
var swiperHistoryBd = new Swiper('#swiper-history-bd', {
  speed: 500,
  loop: false,
  slidesPerView: 3,
  slideToClickedSlide: false,
  centeredSlides: true,
  initialSlide: 1,
  breakpoints: {
    1000: {
      slidesPerView: 2
    },
    760: {
      centeredSlides: false,
      autoHeight: true,
      slidesPerView: 1
    }
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {
      var index_currentSlide = this.realIndex;
      swiperHistoryHd.slideTo(index_currentSlide, 500, false); // var index_currentSlide = this.realIndex;

      $('#swiper-history-hd').find('.swiper-slide').eq(index_currentSlide).addClass('on').siblings().removeClass('on');
    },
    transitionEnd: function transitionEnd() {}
  },
  thumbs: {// swiper: swiperHistoryHd,
  },
  autoplay: true
});
$('.swiper-history-next').click(function (e) {
  swiperHistoryBd.slideNext();
  e.preventDefault();
});
$('.swiper-history-prev').click(function (e) {
  swiperHistoryBd.slidePrev();
  e.preventDefault();
}); // swiperHistoryBd.controller.control = swiperHistoryHd;
// swiperHistoryHd.controller.control = swiperHistoryBd;

$('#swiper-history-hd .swiper-slide').click(function () {
  var i = $(this).index();
  swiperHistoryBd.slideTo(i);
});
$('.list-culture li').mouseenter(function () {
  var $sub = $(this).find('.text');
  var autoHeight = $sub.get(0).scrollHeight;
  $sub.css({
    "height": autoHeight
  });
}).mouseleave(function () {
  var $sub = $(this).find('.text');
  $sub.css({
    "height": 0
  });
});
var swiperHonor = new Swiper('#swiper-honor', {
  loop: false,
  // loopedSlides: 10,
  spaceBetween: 5,
  speed: 500,
  slidesPerView: 5,
  slidesPerGroup: 5,
  pagination: {
    el: '.swiper-pagination-honor',
    clickable: true
  },
  breakpoints: {
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    760: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 6,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
      }
    }
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd() {}
  },
  autoplay: true
});

},{}],7:[function(require,module,exports){
"use strict";

if ($('.nav-inner').length) {
  if ($('.nav-inner .on').length) {
    $('.nav-inner').scrollCenter('.on');
  }
}

},{}],8:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  $(".m_nav").pushmenu({
    button: "#nav_toggle"
  });
  $("#m-nav li").each(function () {
    var sub = $(this).find(".sub-menu");
    var link = sub.find("a").length > 0;

    if (sub && link) {
      $(this).addClass("has-sub");
      $(this).children("a").append('<div class="iconfont icon-next"></div>');
    }
  });
  $(".m-nav-list > li > a").click(function () {
    var sub = $(this).next(".sub-menu");

    if (!$(this).parents("li").hasClass("has-sub")) {
      return true;
    }

    if (sub.is(":visible")) {
      sub.slideUp();
      $(this).removeClass("on");
    } else {
      $("#m-nav .sub-menu").slideUp();
      $("#m-nav li .on").removeClass("on");
      $(this).addClass("on");
      sub.slideDown();
    }

    return false;
  }); // 锚点点击关闭mask

  $('#m-nav .sub-menu a').click(function (e) {
    if (this.hash && $(this.hash).length > 0) {
      var offset = $('.m_header').height();
      var Scroll = $(this.hash).offset().top - offset;
      $('html,body').stop(false, true).animate({
        'scrollTop': Scroll + 'px'
      }, 400);
      $('#nav_toggle').click();
    }
  });
});

},{}],9:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  // Code that uses jQuery's $ can follow here.
  var $body = $('body');
  var $hd = $('#hd');
  var isTransparent = $body.hasClass('transparent-hd');
  ;

  (function () {
    var timer;
    $('.nav li').mouseenter(function () {
      var sub = $(this).find('.pad');
      var subLink = sub.find('a').length > 0;

      if (sub.length && sub.find('a').length) {
        clearTimeout(timer);
        var autoHeight = sub.get(0).scrollHeight;
        $('body').addClass('show-sub');
        sub.css({
          "height": autoHeight
        });
      }

      if (isTransparent) {// $hd.addClass('white-hd')
      }
    }).mouseleave(function () {
      var sub = $(this).find('.pad');
      timer = setTimeout(function () {
        $('body').removeClass('show-sub');
      }, 200);

      if (sub.length) {
        sub.css({
          "height": 0
        });
      }

      if (isTransparent && !$hd.hasClass('white-hd')) {// $hd.removeClass('white-hd')
      }
    });
    $(window).scroll(function () {
      var isPc = $('#hd').is(':visible');
      var $hd = isPc ? $('#hd') : $('.m_header');
      var isOpen = $hd.hasClass('show-search');

      if ($(window).scrollTop() > 10) {
        if (isTransparent) {
          $hd.addClass('white-hd');
        }
      } else {
        if (isTransparent) {
          $hd.removeClass('white-hd');
        }
        /*if( isWhite ){
        	$body.addClass('white-hd')	
        }else if( isTransparent ){
        	if(!isOpen){
        		$body.addClass('transparent-hd')	
        	}
        }*/

      }
    }).scroll();
  })(); // 锚点点击关闭mask


  $('.nav .sub-nav a').click(function (e) {
    /*	if(this.hash){
    	$(this).parents('.sub-nav').trigger('mouseleave')
    }
    */
    if (this.hash) {
      var offset = $('#hd').height() + $('.inner-bar').height();
      var Scroll = $(this.hash).offset().top - offset;
      $('html,body').stop(false, true).animate({
        'scrollTop': Scroll + 'px'
      }, 400);
      $(this).parents('.sub-nav').trigger('mouseleave');
      return false;
    }
  });
});
var hash = location.hash;

if (hash && $(hash).length) {
  $('.nav-inner li').each(function (i) {
    var a = $(this).find('a');

    if (a[0].hash == hash) {
      setTimeout(function () {
        window.scrollTo(0, 0);
        a.click();
      }, 0);
      return false;
    }
  });
}

},{}],10:[function(require,module,exports){
"use strict";

new Swiper('#swiper-news-hot', {
  loop: true,
  // loopedSlides: 10,
  speed: 500,
  pagination: {
    el: '.swiper-pagination-news',
    clickable: true
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd() {}
  },
  autoplay: true
});

},{}],11:[function(require,module,exports){
"use strict";

var _util = require("./_util.js");

if ($('.pro-detail').length) {
  var stick = function stick() {
    var isPc = $('#hd').is(':visible');
    var stickyBottomOffset = $('#fd').outerHeight(true, true);
    var topSpacing = isPc ? $('#hd').height() : $('.m_header').height();
    $productImg.sticky({
      topSpacing: topSpacing + $breadcrumbs.height(),
      bottomSpacing: stickyBottomOffset + $('.product-recommend').outerHeight(true, true)
    });
    $breadcrumbs.sticky({
      topSpacing: topSpacing,
      zIndex: 50
    });
  };

  var unstick = function unstick() {
    $productImg.unstick();
    $breadcrumbs.unstick();
  };

  var stickProduct = function stickProduct() {
    var windowWidth = $(window).width();

    if (windowWidth > 1000) {
      stick();
    } else {
      unstick();
    }
  };

  var createRecommendSwiper = function createRecommendSwiper() {
    return new Swiper('#swiper-recommend', {
      loop: false,
      speed: 500,
      slidesPerView: 4,
      navigation: {
        nextEl: '.swiper-circle-next',
        prevEl: '.swiper-circle-prev'
      },
      breakpoints: {
        769: {
          slidesPerView: 3
        }
      },
      on: {
        init: function init() {},
        slideChangeTransitionStart: function slideChangeTransitionStart() {},
        transitionEnd: function transitionEnd() {}
      },
      autoplay: true
    });
  };

  var swiperRecommendPC = function swiperRecommendPC() {
    if ($(window).width() >= 760) {
      if (!initRecommend) {
        initRecommend = true;
        swiperRecommend = createRecommendSwiper();
      }
    } else if (initRecommend) {
      swiperRecommend.destroy();
      initRecommend = false;
    }
  };

  (0, _util.setWrap)('.pro-detail', true);
  var $breadcrumbs = $('.breadcrumbs-bar');
  var $productImg = $(".pro-detail .img");
  var initRecommend = false;
  var swiperRecommend;
  $(window).on('resize', function () {
    (0, _util.setWrap)('.pro-detail', true);
    swiperRecommendPC();
    stickProduct();
  }).resize();
}

},{"./_util.js":17}],12:[function(require,module,exports){
"use strict";

function toggleProMenu() {
  $('.pro-menu h4').click(function () {
    var $con = $(this).next('.sub-pro');
    $('.pro-menu .on').removeClass('on');

    if ($con.is(':visible')) {
      $(this).removeClass('on');
      $con.slideUp();
      $(this).find('.iconfont').addClass('icon-plus').removeClass('icon-minus');
    } else {
      $('.pro-menu .sub-pro').slideUp();
      $('.pro-menu .iconfont').addClass('icon-plus').removeClass('icon-minus');
      $(this).addClass('on');
      $con.slideDown();
      $(this).find('.iconfont').addClass('icon-minus').removeClass('icon-plus');
    }

    return false;
  });
}

function setPcProActive() {
  $('.pro-menu .sub-pro').each(function () {
    var isActive = $(this).find('.active');

    if (isActive.length) {
      $(this).prev('h4').click();
    }
  });
}

var select_cat = '';
var select_pro = [];

function createProductSelect() {
  var title = $('.pro-side h3').text();
  $('.pro-menu li').each(function (i) {
    var text = $(this).find('h4').find('span').text();
    select_cat += '<option value="' + i + '">' + text + '</option>';
    var sub_array = $(this).find('.sub-pro').find('a').map(function () {
      var text = $(this).text();
      var url = $(this).attr('href');
      return {
        text: text,
        url: url
      };
    }).get();
    select_pro.push(sub_array);
  });
  var html_str = '<div class="m-pro-select m-show">' + '<h3>' + title + '</h3>' + '<div class="select-row">' + '<div class="inp-select"><select name="" id="select_cat">' + select_cat + '</select></div>' + '<div class="inp-select"><select name="" id="select_pro"></select></div>' + '</div>' + '</div>';
  $(html_str).insertBefore('.product-index .wp');
}

function catChange() {
  $('#select_cat').change(function (e) {
    var index_cat = $(this).val() * 1;
    $('#select_pro').html('');
    createProOptions(index_cat); // console.log(index_cat);

    $('.pro-menu li').eq(index_cat).find('.sub-pro a:first').click();
  });
}

function proChange() {
  $('#select_pro').change(function (e) {
    var index_cat = $('#select_cat').find('option:selected').index();
    var index_pro = $(this).find('option:selected').index();
    $('.pro-menu li').eq(index_cat).find('.sub-pro a').eq(index_pro).click(); // window.location.href = url;
  });
}

function createProOptions(index) {
  var proArray = select_pro[index];
  var pro_str = '';

  for (var i = 0; i < proArray.length; i++) {
    // pro_str += '<option value="'+proArray[i].url+'">'+proArray[i].text+'</option>';
    pro_str += '<option value="' + i + '">' + proArray[i].text + '</option>';
  }

  $('#select_pro').html(pro_str);
}

function setMobileProActive() {
  // 没有active的时候
  if ($('.pro-menu .active').length == 0) {
    createProOptions(0);
    $('#select_cat').val(0).change();
    return false;
  }

  $('.pro-menu li').each(function () {
    var isActive = $(this).find('.active');

    if (isActive.length) {
      // var index_cat = isActive.index()
      // var index_pro = $(this).find('.sub-pro').find('a').index('.active')
      var index_pro = isActive.index(); //小类

      var index_cat = isActive.parents('li').index(); //大类

      createProOptions(index_cat); // console.log('index_pro',index_pro);
      // console.log('index_cat',index_cat);
      // $('#select_cat option').eq(index_cat).attr('selected','selected');
      // $('#select_pro option').eq(index_pro).attr('selected','selected');
      // $('#select_cat').val(index_cat).change();
      // $('#select_pro').val(index_pro).change();
      // $('#select_cat').val(index_cat)
      // $('#select_pro').val(index_pro).change();

      $('#select_cat option').eq(index_cat).attr('selected', 'selected');
      $('#select_pro option').eq(index_pro).attr('selected', 'selected');
      return false;
    }
  });
}

$(function () {
  if ($('.product-index').length) {
    toggleProMenu();
    createProductSelect();

    if (!$('#hd').is(':visible')) {
      catChange();
      proChange();
      setTimeout(function () {
        setMobileProActive();
      }, 0);
    } else {
      setTimeout(function () {
        setPcProActive();
      }, 0);
    }
  }
});

},{}],13:[function(require,module,exports){
"use strict";

$('.list-recruit .hd').click(function () {
  var $con = $(this).next('.bd');
  $('.list-recruit .on').removeClass('on');

  if ($con.is(':visible')) {
    $(this).removeClass('on');
    $con.slideUp();
    $(this).find('.iconfont').addClass('icon-plus').removeClass('icon-minus');
  } else {
    $('.list-recruit .bd').slideUp();
    $('.list-recruit .iconfont').addClass('icon-plus').removeClass('icon-minus');
    $(this).addClass('on');
    $con.slideDown();
    $(this).find('.iconfont').addClass('icon-minus').removeClass('icon-plus');
  }

  return false;
});

},{}],14:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  var $hd = $('#hd');
  var $body = $('body');
  var $input = $(".search-main input");
  $('.search-ico').click(function (e) {
    $body.toggleClass('show-search');

    if ($body.hasClass('transparent-hd')) {
      // $body.addClass('white-hd-bg')
      $hd.addClass('white-hd');
    } // $body.removeClass('show-m-search').toggleClass('show-pc-search')

    /*if( $body.hasClass('white-hd') ){
    	$body.addClass('black-hd-bg')
    }else if( $body.hasClass('transparent-hd') ){
    	$body.addClass('white-hd-bg')
    }*/


    setTimeout(function () {
      $input.focus();
    }, 300);
    return false;
  });
  $('body').click(function (e) {
    var container = $(".search-main");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeSearch();
    }
  });
  $('.search-main .icon-x').click(function (e) {
    closeSearch();
    return false;
  });

  function closeSearch() {
    $input.val('');
    $body.removeClass('show-search');

    if ($body.hasClass('transparent-hd') && !$hd.hasClass('white-hd')) {
      $hd.removeClass('white-hd');
    }
  }
});
/*jQuery(document).ready(function($) {
	var $trigger = $('.m-search-ico');
	var $search = $('.search-m');
	var $input = $(".search-m .form-ele"); 
	var $close =  $search.find('.icon-x'); 
	var $body = $('body');
	var $nav_toggle = $('#nav_toggle');
	
	$trigger.click(function (e) {
		if( $nav_toggle.hasClass('active') ){
			$nav_toggle.click()
		}
		$body.removeClass('show-pc-search').toggleClass('show-m-search');
		setTimeout(function(){
			$input.focus()
		},300)
		return false;
	});
	$close.click(function (e) {
		closeSearch()
	});
	
	function closeSearch(){
		$input.val('')
		$body.removeClass('show-m-search')
	}
	
	
	$('body').click(function(e){
	    var container = $(".search-m");
	       if (!container.is(e.target)
	           && container.has(e.target).length === 0)
	       {
				closeSearch()
	       }
	})
});
*/

},{}],15:[function(require,module,exports){
"use strict";

function stickNav() {
  var $nav = $('.inner-bar');
  var isPc = $('#hd').is(':visible');
  if ($nav.length == 0) return;
  $nav.sticky({
    topSpacing: isPc ? $('#hd').height() : $('.m_header').height(),
    zIndex: 80
  });
  $nav.sticky('update');
}

stickNav();
var fixnavBar = debounce(function () {
  stickNav();
}, 250, false); // 公司简介

function introAnchor() {
  var isPc = $('#hd').is(':visible');
  var offset = isPc ? $('#hd').height() + $('.inner-bar').height() : $('.m_header').height() + $('.inner-bar').height();
  $('.nav-inner a').pageNav({
    scroll_shift: offset,
    active_shift: offset
  });
}

if ($('.inner-bar').length) {
  $(window).on('resize', fixnavBar);
}

if ($('#swiper-history-bd').length) {
  introAnchor();
}

},{}],16:[function(require,module,exports){
"use strict";

var swiperPatent = new Swiper('#swiper-patent', {
  loop: true,
  // loopedSlides: 10,
  speed: 500,
  slidesPerView: 5,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-circle-next',
    prevEl: '.swiper-circle-prev'
  },
  breakpoints: {
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    760: {
      slidesPerView: 1.4,
      slidesPerGroup: 1,
      spaceBetween: 25,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
      }
    }
  },
  on: {
    init: function init() {},
    slideChangeTransitionStart: function slideChangeTransitionStart() {},
    transitionEnd: function transitionEnd() {}
  },
  autoplay: true
});

},{}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pad = exports.judgeClient = void 0;
exports.setWrap = setWrap;

var judgeClient = function judgeClient() {
  var client = '';

  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    client = 'iOS';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    client = 'Android';
  } else {
    client = 'PC';
  }

  return client;
};

exports.judgeClient = judgeClient;

var pad = function pad(n) {
  return n < 10 ? "0" + n : n;
};

exports.pad = pad;

function setWrap(wp, right) {
  var windowWidth = $(window).width();
  var wpWidth = $('#fd .wp').width();

  if (windowWidth <= 1700) {
    var left = $(window).width() / 100 * 5;
  } else {
    var left = (windowWidth - wpWidth) / 2;
  }

  if (!right) {
    $(wp).css({
      paddingLeft: Math.floor(left)
    });
  } else {
    $(wp).css({
      paddingRight: Math.floor(left)
    });
  }
}

},{}],18:[function(require,module,exports){
"use strict";

require("./_nav.js");

require("./_countTo.js");

require("./_search.js");

require("./_m-inner-nav.js");

require("./_m-nav.js");

require("./_stick_inner_bar.js");

require("./_index.js");

require("./_intro.js");

require("./_tec.js");

require("./_applaction.js");

require("./_news.js");

require("./_product.js");

require("./_product-detail.js");

require("./_recruit.js");

require("./_form.js");

require("./_about-duty.js");

},{"./_about-duty.js":1,"./_applaction.js":2,"./_countTo.js":3,"./_form.js":4,"./_index.js":5,"./_intro.js":6,"./_m-inner-nav.js":7,"./_m-nav.js":8,"./_nav.js":9,"./_news.js":10,"./_product-detail.js":11,"./_product.js":12,"./_recruit.js":13,"./_search.js":14,"./_stick_inner_bar.js":15,"./_tec.js":16}]},{},[18]);
